
import classes from "./Order.module.css";

const Order = (props) => {

const usd = props.order.quantity * props.order.exchangeEntryPrice
const profit = props.order.pnl / usd * 100

  return (
      <div className={classes.orderContainer}>
        <div className={classes.textContainer}>
          <p className={classes.title}>{"entry price: " + props.order.exchangeEntryPrice}</p>
          <p className={classes.title}>{"entry: " + props.order.entryDate}</p>
          <p className={classes.title}>{"qty: " + props.order.quantity}</p>
        </div>

        <div className={classes.textContainer}>
          <p className={classes.title}>{"exit price __:" + props.order.exchangeExitPrice}</p>
          <p className={classes.title}>{"exit: " + props.order.exitDate}</p>
          <p className={classes.title}>{"pnl: " + props.order.pnl}</p>
          <p className={classes.title}>{"pnl%: " + profit + "%"}</p>
        </div>
      </div>
  );
};

export default Order;
