
import {Route, Switch} from "react-router-dom"
import Main from './pages/Main';
import Details from './pages/Details';


function App() {
  return (
    <div className="App">
    <Switch>
        <Route path="/" exact>
        <Main></Main>
        </Route>
        <Route path="/details/:botId" exact>
        <Details></Details>

        </Route>
      </Switch>
    </div>
  );
}

export default App;
