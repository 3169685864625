import classes from "./TradingBotList.module.css";
import TradingBot from "./TradingBot";

const UserList = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.usersContainer}>
        {props.bots.map((bot) => (
          <TradingBot bot={bot} key={bot._id} />
        ))}
      </div>
    </div>
  );
};

export default UserList;
