export async function getBots() {
  const response = await fetch(`/api/bots/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: "abuser7944",
    },
    method: "GET",
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not fetch product.");
  }
  return data;
}

export async function getBot(id) {
    const response = await fetch(`/api/bots/orders/` + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: "abuser7944",
      },
    });
    const data = await response.json();
  
    console.log("get user");
    if (!response.ok || data.error) {
      throw new Error(data.message || "Could not fetch product.");
    }
  
    return data;
  }
  