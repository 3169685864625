import { React, useEffect } from "react";
import useHttp from "../hooks/use-http";
import TradingBotList from "../components/TradingBotList";
import { getBots } from "../network/api";

const Main = () => {
  const { sendRequest, status, data: data, error } = useHttp(getBots, true);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  if (!data) {
    return <div></div>;
  } else {
    console.log(data)
  }
  return (
    <div>
      <TradingBotList bots={data}></TradingBotList>
    </div>
  );
};

export default Main;
