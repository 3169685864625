import TradingBot from "../components/TradingBot";
import { getBot } from "../network/api";
import useHttp from "../hooks/use-http";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Order from "../components/Order";

const Details = () => {
  const params = useParams();
  const { botId } = params;

//   const [bot, setBot] = useState();

  const {
    sendRequest,
    status,
    data,
    error,
  } = useHttp(getBot, true);

  useEffect(() => {
    console.log(botId);
    // const params = { id: botId };
    sendRequest(botId);
  }, [sendRequest]);

  if (!data) {
    return <div>Missing bot</div>;
  }

  if (data) {
    return (<div> 
        <TradingBot bot={data}></TradingBot>
        {data.orders.map((order) => (
          <Order order={order} key={order._id} />
        ))}
    </div>);
  }

  return (<div>

  </div>);
};

export default Details;
